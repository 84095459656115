<template>
  <a-modal
    v-model="isShow"
    centered
    :mask-closable="false"
    :ok-button-props="{ props: { loading: submitting } }"
    title="编辑用户"
    @ok="handleSubmit"
  >
    <a-form
      :form="form"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 15 }"
      @submit="handleSubmit"
    >

      <a-form-item label="登录账号">
        <a-input
          v-decorator="['username', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入登录账号' },
              { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '只能由4-50位 字母、数字 或 下划线 组成' },
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="登录密码">
        <a-input-password
          autocomplete="new-password"
          v-decorator="['password', {
            rules: [
              { min: 6, message: '最少6个字符' },
              { max: 50, message: '最多50个字符' },
              { pattern: /^\S.*\S$/, message: '首尾不能为空格' }
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="姓名">
        <a-input
          v-decorator="['full_name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入姓名' },
              { max: 50, message: '最多50个字符' },
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="手机号">
        <a-input
          v-decorator="['phone_number', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入手机号' },
              { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' },
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="联系邮箱">
        <a-input
          v-decorator="['email', {
            normalize: this.$lodash.trim,
            rules: [
              { max: 100, message: '最多100个字符' },
              { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' },
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="角色">
        管理员
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { findUserFormByAdmin, updateUserByAdmin } from '@/api/user'

export default {
  name: 'EditUser',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'user' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      findUserFormByAdmin(this.id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue(res.data)
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateUserByAdmin(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
