var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑用户"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"登录账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['username', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入登录账号' },
            { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '只能由4-50位 字母、数字 或 下划线 组成' } ]
        }]),expression:"['username', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入登录账号' },\n            { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '只能由4-50位 字母、数字 或 下划线 组成' },\n          ]\n        }]"}]})],1),_c('a-form-item',{attrs:{"label":"登录密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
          rules: [
            { min: 6, message: '最少6个字符' },
            { max: 50, message: '最多50个字符' },
            { pattern: /^\S.*\S$/, message: '首尾不能为空格' }
          ]
        }]),expression:"['password', {\n          rules: [\n            { min: 6, message: '最少6个字符' },\n            { max: 50, message: '最多50个字符' },\n            { pattern: /^\\S.*\\S$/, message: '首尾不能为空格' }\n          ]\n        }]"}],attrs:{"autocomplete":"new-password"}})],1),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['full_name', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入姓名' },
            { max: 50, message: '最多50个字符' } ]
        }]),expression:"['full_name', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入姓名' },\n            { max: 50, message: '最多50个字符' },\n          ]\n        }]"}]})],1),_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入手机号' },
            { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' } ]
        }]),expression:"['phone_number', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入手机号' },\n            { pattern: /^1[3-9]\\d{9}$/, message: '手机号格式不正确' },\n          ]\n        }]"}]})],1),_c('a-form-item',{attrs:{"label":"联系邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
          normalize: this.$lodash.trim,
          rules: [
            { max: 100, message: '最多100个字符' },
            { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' } ]
        }]),expression:"['email', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { max: 100, message: '最多100个字符' },\n            { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' },\n          ]\n        }]"}]})],1),_c('a-form-item',{attrs:{"label":"角色"}},[_vm._v(" 管理员 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }